import {
  Component,
  NgModule,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import {
  CadastroAgencia,
  SocioAgenciaCadastro
} from "../../model";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {
  AgenciaService
} from "../../services";
import {
  NgxMaskModule
} from 'ngx-mask';
import {
  debounceTime
} from "rxjs/operators";
import {
  AMBIENTE
} from '../../app.config';

@Component({
  selector: "sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})

export class SignUpComponent implements OnInit {
  numeroSocios: number = 1;
  socioCadastroAgencia: SocioAgenciaCadastroInterface[] = [];
  form: FormGroup;
  mostrarMensagemErro: boolean = false;
  mostrarMensagemSucesso: boolean = false;
  mensagemSucesso: string = "";
  ambiente = AMBIENTE;
  isChecked: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private agenciaService: AgenciaService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      // Identificação
      razaoSocial: this.formBuilder.control("", [
        Validators.required,
      ]),
      nomeFantasia: this.formBuilder.control("", [
        Validators.required,
      ]),
      cnpjAgencia: this.formBuilder.control("", [
        Validators.required,
        Validators.pattern(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/),
      ]),
      identificacaoEstadual: this.formBuilder.control("", [
        Validators.required,
      ]),
      identificacaoMunicipal: this.formBuilder.control("", [
        Validators.required,
      ]),
      embratur: this.formBuilder.control("", [
        Validators.required,
      ]),

      //Contatos
      telefonePrincipal: this.formBuilder.control("", [
        Validators.required,
        // Validators.pattern(/^\(\d{2}\) \d{4,5}-\d{4}$/),
      ]),
      telefoneSecundario: this.formBuilder.control("", [
        // Validators.pattern(/^\(\d{2}\) \d{4,5}-\d{4}$/),
      ]), // optional
      emailAgencia: this.formBuilder.control("", [
        Validators.required,
        Validators.email,
      ]),
      website: this.formBuilder.control(""), // optional

      //Endereço
      cep: this.formBuilder.control("", [
        Validators.required,
        Validators.pattern(/^\d{5}-\d{3}$/),
      ]),
      logradouro: this.formBuilder.control("", [
        Validators.required,
      ]),
      numero: this.formBuilder.control("", [
        Validators.required,
      ]),
      bairro: this.formBuilder.control("", [
        Validators.required,
      ]),
      cidade: this.formBuilder.control("", [
        Validators.required,
      ]),
      estado: this.formBuilder.control("", [
        Validators.required,
      ]),
      complemento: this.formBuilder.control(""),

      //Bank data
      nomeBanco: this.formBuilder.control("", [Validators.required]),
      numeroBanco: this.formBuilder.control("", [Validators.required]),
      agenciaBanco: this.formBuilder.control("", [Validators.required]),
      numeroConta: this.formBuilder.control("", [Validators.required]),

      //Additional info
      idioma: this.formBuilder.control("", [
        Validators.required,
      ]),
      quantidadeSocios: this.formBuilder.control(""),

      //Files of agency
      contratoSocial: [null, Validators.required],
      cartaoCPJ: [null, Validators.required],
      comprovanteEndereco: [null, Validators.required],
      identificacaoBancaria: [null, Validators.required],
      doccadastur: [null, Validators.required],
      docembratur: [null, Validators.required],
      logoempresa: [null, Validators.required],

      //Info Socio

      // nomeSocio: this.formBuilder.control(""),
      // cpfSocio: this.formBuilder.control(""),
      // rgSocio: this.formBuilder.control(""),
      // telefoneSocio: this.formBuilder.control(""),
      // emailSocio: this.formBuilder.control(""),
      // enderecoSocio: this.formBuilder.control(""),
      // rgcnhSocio: [],
      // comprovanteResidenciaSocio: [],
    });

    this.confirmaQtdeSocios();
    this.aplicarMascaraTelefone();
    this.aplicarMascaraTelefoneSocios();
    this.aplicarMascaraCEP();
    this.aplicarMascaraCNPJ();
  }

  confirmaQtdeSocios() {
    if (this.numeroSocios == 0) {
      this.numeroSocios = 1;
    }
    if (this.numeroSocios < 4) {
      for (let i = 0; i < this.numeroSocios; i++) {
        const socio: SocioAgenciaCadastroInterface = {
          nome: "",
          cpf: "",
          rg: "",
          telefone: "",
          email: "",
          endereco: "",
          rgcnh: null,
          comprovanteResidencia: null
        };
        this.socioCadastroAgencia.push(socio);
      }
    } else {
      alert("Máximo 3 sócios");
    }
  }
  AlteraisChecked(check: any) {
    this.isChecked = check.target.checked;
    console.log(this.isChecked);
  }
  limpaNumSocios() {
    this.socioCadastroAgencia = [];
  }

  getFormValidationErrors() {
    const invalidFields: any[] = [];
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors = this.form.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          invalidFields.push({
            field: key,
            error: keyError,
            value: controlErrors[keyError]
          });
        });
      }
    });
    return invalidFields;
  }

  fileInput(event: any, key: string = "", socio: SocioAgenciaCadastroInterface = null) {
    const dados = ["rgcnh", "comprovanteResidencia"];

    if (!dados.includes(key) && event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0]; // Seleciona o primeiro arquivo

      this.form.get(key).setValue(file);
      // Atualize o FormGroup para armazenar o arquivo
      // this.form.get(key)?.setValue(null);
    } else {
      const file = event.target.files[0];

      socio[key] = file;
    }
  }



  enviarCadastro() {
    this.mostrarMensagemErro = false;

    // Lista dos campos de arquivos que devem ser validados
    const arquivosObrigatorios = [
      'contratoSocial',
      'cartaoCPJ',
      'comprovanteEndereco',
      'identificacaoBancaria',
      'doccadastur',
      'docembratur',
      'logoempresa'
    ];

    let arquivoFaltando = false;

    // Verifica se algum arquivo obrigatório está faltando
    arquivosObrigatorios.forEach(campo => {
      const control = this.form.get(campo);
      if (!control.value) {
        arquivoFaltando = true;
        control.markAsTouched(); // Marca o campo como tocado para exibir a mensagem de erro
      }
    });

    // Verifica se o formulário é inválido, se há algum arquivo faltando ou se os campos dos sócios estão incompletos
    if (this.form.invalid || arquivoFaltando || !this.validarCamposSocios()) {
      this.mostrarMensagemErro = true;
      return; // Impede o envio se o formulário estiver inválido, se algum arquivo estiver faltando ou se os campos dos sócios estiverem incompletos
    }

    const formData = new FormData();

    // Adiciona todos os campos ao FormData
    for (const key in this.form.controls) {
      const control = this.form.get(key);

      if (control.value instanceof File) {
        formData.append(key, control.value);
      } else if (key === 'quantidadeSocios') {
        formData.append(key, this.numeroSocios.toString());
      } else {
        formData.append(key, control.value || '');
      }
    }

    // Adiciona informações dos sócios, se houver
    if (this.numeroSocios > 0) {
      for (let i = 1; i <= this.numeroSocios; i++) {
        const socio = this.socioCadastroAgencia[i - 1];
        formData.append(`nomeSocio${i}`, socio.nome);
        formData.append(`cpfSocio${i}`, socio.cpf);
        formData.append(`rgSocio${i}`, socio.rg);
        formData.append(`telefoneSocio${i}`, socio.telefone);
        formData.append(`emailSocio${i}`, socio.email);
        formData.append(`enderecoSocio${i}`, socio.endereco);
        formData.append(`rgcnhSocio${i}`, socio.rgcnh);
        formData.append(`comprovanteResidenciaSocio${i}`, socio.comprovanteResidencia);
      }
    }

    // Realiza o envio do cadastro
    this.agenciaService.criarCadastroAgencia(formData, this.numeroSocios).subscribe(
      (dados) => {
        this.mostrarMensagemSucesso = dados;
        this.form.reset();
        this.mensagemSucesso = "Cadastro realizado com sucesso! Aguarde a análise, e entraremos em contato.";
      },
      (error) => {
        console.error('Erro ao cadastrar agência:', error);
      }
    );
  }

  // MÁSCARAS DE INPUT

  aplicarMascaraTelefone() {
    const camposTelefone = ['telefonePrincipal', 'telefoneSecundario']; -
    camposTelefone.forEach(campo => {
      const telefoneControl = this.form.get(campo);

      if (telefoneControl) {
        telefoneControl.valueChanges.subscribe(value => {
          if (value) {
            const telefoneFormatado = this.formatarTelefone(value);
            telefoneControl.setValue(telefoneFormatado, {
              emitEvent: false
            });
          }
        });
      }
    });
  }

  formatarTelefone(value: string): string {
    const telefoneNumeros = value.replace(/\D/g, '');

    return telefoneNumeros
      .replace(/^(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }


  aplicarMascaraCNPJ() {
    const cnpjControl = this.form.get('cnpjAgencia');
    if (cnpjControl) {
      cnpjControl.valueChanges.subscribe(value => {
        if (value) {
          const cnpjFormatado = this.formatarCNPJ(value);
          cnpjControl.setValue(cnpjFormatado, {
            emitEvent: false
          }); // Formata o valor enquanto o usuário digita
        }
      });
    }
  }

  formatarCNPJ(value: string): string {
    const cnpjNumeros = value.replace(/\D/g, ''); // Remove qualquer caractere que não seja número

    // Aplica a máscara conforme o número de dígitos
    return cnpjNumeros
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/\.(\d{3})(\d)/, '.$1/$2')
      .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
  }

  aplicarMascaraCPF(socio: any) {
    if (socio.cpf) {
      socio.cpf = this.formatarCPF(socio.cpf);
    }
  }

  formatarCPF(value: string): string {
    const cpfNumeros = value.replace(/\D/g, ''); // Remove qualquer caractere que não seja número

    return cpfNumeros
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }


  aplicarMascaraRG(socio: any) {
    if (socio.rg) {
      socio.rg = this.formatarRG(socio.rg);
    }
  }

  formatarRG(value: string): string {
    const rgNumeros = value.replace(/\D/g, ''); // Remove qualquer caractere que não seja número

    return rgNumeros
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1})$/, '$1-$2');
  }

  aplicarMascaraTelefoneSocios() {
    this.socioCadastroAgencia.forEach(socio => {
      if (socio.telefone) {
        const telefoneFormatado = this.formatarTelefoneSocio(socio.telefone);
        socio.telefone = telefoneFormatado;
      }
    });
  }

  formatarTelefoneSocio(value: string): string {
    const telefoneNumeros = value.replace(/\D/g, ''); // Remove qualquer caractere que não seja número

    if (telefoneNumeros.length <= 10) {
      // Formato: (99) 9999-9999
      return telefoneNumeros
        .replace(/^(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2');
    } else {
      // Formato: (99) 99999-9999
      return telefoneNumeros
        .replace(/^(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2');
    }
  }

  aplicarMascaraCEP() {
    const cepControl = this.form.get('cep');
    if (cepControl) {
      cepControl.valueChanges.subscribe(value => {
        if (value) {
          const cepFormatado = this.formatarCEP(value);
          cepControl.setValue(cepFormatado, {
            emitEvent: false
          });
        }
      });
    }
  }


  formatarCEP(value: string): string {
    const cepNumeros = value.replace(/\D/g, ''); // Remove qualquer caractere que não seja número

    if (cepNumeros.length > 5) {
      // Formata como XXXXX-XXX
      return cepNumeros.replace(/^(\d{5})(\d{1,3})$/, '$1-$2');
    } else {
      return cepNumeros; // Retorna o CEP enquanto ele é digitado, mesmo que incompleto
    }
  }


  // VALIDAÇÕES DE SOCIO

  validarCamposSocios(): boolean {
    let camposValidos = true;

    this.socioCadastroAgencia.forEach((socio, index) => {
      if (
        !socio.nome ||
        !socio.cpf ||
        !socio.rg ||
        !socio.telefone ||
        !socio.email ||
        !socio.endereco ||
        !socio.rgcnh ||
        !socio.comprovanteResidencia
      ) {
        camposValidos = false;
        // Marcar os campos como tocados para que as mensagens de erro sejam exibidas
        this.mostrarMensagemErro = true;
      }
    });

    return camposValidos;
  }

}

interface SocioAgenciaCadastroInterface {
  nome: string;
  cpf: string;
  rg: string;
  telefone: string;
  email: string;
  endereco: string;
  rgcnh: File;
  comprovanteResidencia: File;
}
