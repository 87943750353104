
export class FormaPagamento {
  Id: number;
  Descricao: string;
  TipoPagamento: any;
  TipoPagamentoDesc: string;
  Condicoes = [];
  ValorMinimoParcela: number;
  Ativo: boolean;
  UsoInterno: boolean;
  NaoAssociaOutraForma: boolean;
  ExigeValorTotal: boolean;
  Alteracoes: any;
  Observacoes: any;
  PermitePagamentoTerceiros: boolean = true;
}

export class CondicaoPagamento {
  Id: number = 0;
  Descricao: string;
  FormaPagamentoId: number;
  Ativo: boolean;
  UsoInterno: boolean;
  Coeficiente: any;
  MaxDiasPrimeiraParcela: any;
  Parcelas: any;
  public WebService?: any = { Descricao: '' };
  public WebServiceId: number;
}

export class CondicaoPagamento_Integrador {
  public WebService?: any = { Descricao: '' };
}
