import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoginService } from './login.service';
import { catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginLightComponent } from '../auth/login-light/login-light.component';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService, private modalService: NgbModal,private router: Router) { }

  intercept(request: any, next: HttpHandler): Observable<HttpEvent<any>> {
    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{

    const loader = request.headers.keys();
    const noauth = (loader.indexOf('noauth') != -1);
    const currentUrl = this.router.url;
    if (!noauth && this.loginService.isLoggedIn()) {
      const authRequest = request.clone({ setHeaders: { 'Authorization': 'Bearer ' + this.loginService.user().access_token } });
      return next.handle(authRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status == 401) {
            // this.openModalLogin();
            this.loginService.logout();
            window.location.reload();
          }
          return throwError(error);
        })
      );
    } else {
      if (noauth) {
        request.headers = request.headers.delete('noauth');
      }
      return next.handle(request);
    }
  }

  openModalLogin() {
    this.modalService.open(LoginLightComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered: true, keyboard: false });
  }


}
