import {
  Component,
  HostListener,
  Input,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ContratosService, CidadeService } from "../services";
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidationErrors,
  AbstractControl,
} from "@angular/forms";
import { Assinatura } from "../model";
import { API } from "../app.config";
import * as moment from "moment";
import SignaturePad from "signature_pad";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NbStepperComponent } from "@nebular/theme";
import { MASKS } from "ng-brazil";

@Component({
  selector: "contrato",
  templateUrl: "./contrato.component.html",
  styleUrls: ["./contrato.component.scss"],
})
export class ContratoComponent implements OnInit {
  chave: string;
  logo: string;
  assinatura: Assinatura = new Assinatura();
  contrato: SafeResourceUrl;
  form: FormGroup;
  LoadingAlteracaoFrente = false;
  LoadingAlteracaoVerso = false;
  mensagensErro = [];
  mensagemSucesso = "";
  stream: any;
  Api = API;
  public MASKS = MASKS;
  PassoStep = 0;
  @ViewChild("sPad") signaturePadElement?: ElementRef;
  @ViewChild("video") video: ElementRef;
  @ViewChild("canvas") canvas: ElementRef;
  @ViewChild("stepper") stepper: NbStepperComponent;
  capturedImage: string;
  signaturePad: any;
  private sPad: ElementRef;
  permissaoCamera = false;
  permissaoGeo = false;
  coords: any | undefined;
  photoURL: string;
  photoConfirmed: boolean = false;
  maxDate: string;
  @ViewChild("sPad") set content(content: ElementRef) {
    if (content) {
      this.sPad = content;
      this.signaturePad = new SignaturePad(this.sPad.nativeElement);
    }
  }
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private contratosService: ContratosService,
    private formBuilder: FormBuilder,
    private cidadeService: CidadeService,
    private sanitizer: DomSanitizer
  ) {}
  public display = {
    Nome: "Nome",
    DataNascimento: "Data de Nascimento",
    Cpf: "CPF",
    RG: "RG",
    SSP: "Orgão Expedidor",
    CEP: "CEP",
    Cidade: "Cidade",
    Estado: "Estado",
    Bairro: "Bairro",
    Rua: "Rua",
    Numero: "Número",
    Desde: "Residente desde",
    Email: "Email",
    Celular: "Celular",
    Telefone: "Telefone",
    TipoResidencia: "Residência",
  };
  ngOnInit() {

    this.form = this.formBuilder.group({
      Desde: [this.assinatura.Desde, [Validators.required, this.dateValidator.bind(this)]]
    });

    this.route.queryParams.subscribe((params) => {
      this.chave = params["chave"];
    });

    this.route.queryParams.subscribe((params) => {
      this.chave = params["chave"];
    });
    if (this.chave) {
      this.buscaChave();
    }
    this.form = this.formBuilder.group({
      Nome: this.formBuilder.control("", [
        Validators.required,
        Validators.minLength(2),
      ]),
      DataNascimento: this.formBuilder.control("", [Validators.required]),
      Cpf: this.formBuilder.control("", [Validators.required]),
      RG: this.formBuilder.control("", [Validators.required]),
      SSP: this.formBuilder.control("", [Validators.required]),
      CEP: this.formBuilder.control("", [Validators.required]),
      Cidade: this.formBuilder.control("", [Validators.required]),
      Estado: this.formBuilder.control("", [Validators.required]),
      Bairro: this.formBuilder.control("", [Validators.required]),
      Rua: this.formBuilder.control("", [Validators.required]),
      Numero: this.formBuilder.control("", [Validators.required]),
      Complemento: this.formBuilder.control(""),
      Desde: this.formBuilder.control("", [Validators.required]),
      Email: this.formBuilder.control("", [Validators.required]),
      Celular: this.formBuilder.control("", [Validators.required]),
      Telefone: this.formBuilder.control("", [Validators.required]),
      TipoResidencia: this.formBuilder.control("", [Validators.required]),
      Ip: this.formBuilder.control(""),
      Localizacao: this.formBuilder.control(""),
    });
    setTimeout(
      () =>
        (document.getElementById("nb-global-spinner").style.display = "none"),
      1000
    );
  }

  ngAfterViewInit() {
    this.startCamera();
    this.checkGeolocationPermission();
    if (this.PassoStep == 3) {
      setTimeout(() => this.ChamaContrato(), 600);
    }
  }

  buscaChave() {
    this.contratosService.GetDadosContrato(this.chave).subscribe(
      (dados) => {
        this.assinatura = dados.Assinatura;
        this.assinatura.DataNascimento = moment(this.assinatura.DataNascimento);
        this.logo = dados.LogoEmpresa;
      },
      (erro) => {
        alert(
          "Ocorreu um erro. Por favor, tente novamente. Detalhes do erro: " +
            erro
        );
      }
    );
  }

  applyDateMask(event: any): void {
    let value = event.target.value.replace(/\D/g, '');
    if (value.length > 2) {
      value = value.substring(0, 2) + '/' + value.substring(2, 6);
    }
    event.target.value = value;
    this.assinatura.Desde = value;
    this.form.controls['Desde'].setValue(value);

    const [month, year] = value.split('/');
    if (month && year && year.length === 4) {
      const monthNum = parseInt(month, 10);
      const yearNum = parseInt(year, 10);
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();

      // Validações adicionais
      if (monthNum > 12) {
        alert('O mês não pode ser superior a 12.');
        event.target.value = '';
        this.assinatura.Desde = '';
        this.form.controls['Desde'].setValue('');
        return;
      }

      if (yearNum > currentYear) {
        alert('O ano não pode ser superior ao ano atual.');
        event.target.value = '';
        this.assinatura.Desde = '';
        this.form.controls['Desde'].setValue('');
        return;
      }

      if (yearNum === currentYear && monthNum > currentMonth) {
        alert('O mês não pode ser superior ao mês atual.');
        event.target.value = '';
        this.assinatura.Desde = '';
        this.form.controls['Desde'].setValue('');
        return;
      }
    }
  }

  dateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value) {
      const [month, year] = value.split('/');
      if (month && year && year.length === 4) {
        const selectedDate = new Date(`${year}-${month}-01`);
        console.log('Data selecionada:', selectedDate);
        const currentDate = new Date();
        currentDate.setDate(1);
        if (selectedDate > currentDate) {
          return { invalidDate: true };
        }
      }
    }
    return null;
  }

  savePNG() {
    if (this.assinatura.FotoComDocumento == undefined) {
      alert("Por favor, faça o upload da frente do documento!");
    } else if (this.assinatura.DocumentoVerso == undefined) {
      alert("Por favor, faça o upload do verso do documento!");
    } else if (this.capturedImage == undefined) {
      alert("Por favor, capture uma foto segurando seu documento!");
    } else if (this.signaturePad.isEmpty()) {
      alert("Por favor, preencha com a sua assinatura!");
    } else {
      const dataURL = this.signaturePad.toDataURL();
      this.uploadAssinatura(dataURL);
    }
  }
  limpar() {
    this.signaturePad.clear();
  }
  uploadAssinatura(imagem) {
    const binaryStringassinatura = atob(imagem.split(",")[1]);
    const arrayBuffergassinatura = new ArrayBuffer(
      binaryStringassinatura.length
    );
    const uint8Arraygassinatura = new Uint8Array(arrayBuffergassinatura);

    for (let i = 0; i < binaryStringassinatura.length; i++) {
      uint8Arraygassinatura[i] = binaryStringassinatura.charCodeAt(i);
    }
    const assinaturaimg = new Blob([uint8Arraygassinatura], {
      type: "image/png",
    });

    const binaryStringcapturedImage = atob(this.capturedImage.split(",")[1]);
    const arrayBuffercapturedImage = new ArrayBuffer(
      binaryStringcapturedImage.length
    );
    const uint8ArraycapturedImage = new Uint8Array(arrayBuffercapturedImage);

    for (let i = 0; i < binaryStringcapturedImage.length; i++) {
      uint8ArraycapturedImage[i] = binaryStringcapturedImage.charCodeAt(i);
    }

    const capturedImageimg = new Blob([uint8ArraycapturedImage], {
      type: "image/png",
    });
    this.contratosService
      .uploadAssinatura(assinaturaimg, capturedImageimg, this.assinatura.Id)
      .subscribe(
        (dados) => {
          this.assinatura.AssinaturaDigitalizada = dados;
          alert(
            "Sua assinatura foi enviada com sucesso e será submetida para aprovação."
          );
          this.stepper.next();
          this.ChamaContrato();
        },
        (erros) => {
          console.log("Erro ao subir assinatura");
          console.log(erros);
          this.LoadingAlteracaoFrente = false;
          this.mensagensErro = this.errorHandler(erros);
        }
      );
  }

  Avancar1() {
    this.contratosService
      .AtualizarAssinatura(this.assinatura, this.coords)
      .subscribe(
        (dados) => {
          this.assinatura = dados;
          this.assinatura.DataNascimento = moment(
            this.assinatura.DataNascimento
          );
          this.ChamaContrato();
        },
        (erro) => {
          alert(
            "Ocorreu um erro. Por favor, tente novamente. Detalhes do erro: " +
              erro
          );
        }
      );
  }

  ChamaContrato() {
    this.contratosService.GerarPdfContrato(this.assinatura.Id).subscribe(
      (dados) => {
        const fileURL = URL.createObjectURL(dados);
        this.contrato = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        if (this.video) {
          this.video.nativeElement.srcObject = this.stream;
        }
      },
      (erro) => {
        alert(
          "Ocorreu um erro. Por favor, tente novamente. Detalhes do erro: " +
            erro
        );
      }
    );
  }

  Avancar3() {
    //
  }
  buscaCep() {
    if (this.assinatura.CEP !== "") {
    this.cidadeService
      .buscarEnderecoPorCep(this.assinatura.CEP)
      .subscribe((dados) => {
        if (dados.logradouro && dados.logradouro.length > 0)
          this.assinatura.Rua = dados.logradouro;

        if (dados.bairro && dados.bairro.length > 0)
          this.assinatura.Bairro = dados.bairro;

        if (dados.uf && dados.uf.length > 0) this.assinatura.Estado = dados.uf;

        if (dados.localidade && dados.localidade.length > 0)
          this.assinatura.Cidade = dados.localidade;
      });
    }
  }
  dateMask(event, tipo) {
    let v = event.target.value;
    if (v.length == 10) {
      if (tipo == "DataNascimento") {
        this.assinatura.DataNascimento = moment(v, "DD/MM/YYYY");
      }
    }
    if (v.match(/^\d{2}$/) !== null) {
      event.target.value = v + "/";
    } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
      event.target.value = v + "/";
    }
  }
  setDataNascimento(data) {
    this.assinatura.DataNascimento = data;
  }
  getFormValidationErrors(form) {
    let erros = [];
    Object.keys(form.controls).forEach((key) => {
      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          const erro = {
            key: key,
            keyError: keyError,
            value: controlErrors[keyError],
          };
          erros = [...erros, erro];
        });
      }
    });
    return erros;
  }

  alterarDocumentoFrente(imagem) {
    this.LoadingAlteracaoFrente = true;
    this.contratosService
      .uploadDocumentoFrente(imagem, this.assinatura.Id)
      .subscribe(
        (dados) => {
          this.assinatura.FotoComDocumento = dados;
          this.LoadingAlteracaoFrente = false;
          this.mensagemSucesso = "Documento enviado com sucesso!";
        },
        (erros) => {
          console.log("Erro ao subir Documento");
          console.log(erros);
          this.LoadingAlteracaoFrente = false;
          this.mensagensErro = this.errorHandler(erros);
        }
      );
  }
  alterarDocumentoVerso(imagem) {
    this.LoadingAlteracaoVerso = true;
    this.contratosService
      .uploadDocumentoVerso(imagem, this.assinatura.Id)
      .subscribe(
        (dados) => {
          this.assinatura.DocumentoVerso = dados;
          this.LoadingAlteracaoVerso = false;
          this.mensagemSucesso = "Documento enviado com sucesso!";
        },
        (erros) => {
          console.log("Erro ao subir Documento");
          console.log(erros);
          this.LoadingAlteracaoVerso = false;
          this.mensagensErro = this.errorHandler(erros);
        }
      );
  }
  public errorHandler(erro): string[] {
    let mensagem = [];
    switch (erro.status) {
      case 0:
        mensagem.push("o servidor não respondeu, tente novamente mais tarde!");
        break;
      case 400:
        if (erro.error && erro.error.mensagem)
          mensagem.push(erro.error.mensagem);
        else mensagem.push("Houve algum erro na execução, tente novamente!");

        break;
      case 401:
        mensagem.push("você não tem autorização para executar esta ação!");
        break;
      case 404:
        mensagem.push(erro.error.mensagem);
        break;
      case 406:
        mensagem.push(erro.error.mensagem);
        break;
      case 500:
        mensagem.push(
          "Houve um erro interno no servidor! tente novamente, caso o erro persista, entre em contato com o suporte"
        );
        break;
      default:
        mensagem.push(erro.statusText);
    }
    if (erro.error != null && erro.error.mensagens != null) {
      mensagem = [...mensagem, ...erro.error.mensagens];
    }
    return mensagem;
  }

  async checkGeolocationPermission() {
    const result = await this.contratosService.GetPermissaoGeo();
    this.permissaoGeo = result.permitido;
    this.coords = result.coords;
  }

  // async startCamera() {
  //   const result = await this.contratosService.requestCameraPermission();
  //   this.permissaoCamera = result.permitido;
  //   if (result.permitido && result.stream) {
  //     this.stream = result.stream;
  //     if (this.video) {
  //       this.video.nativeElement.srcObject = this.stream;
  //     }
  //   }
  // }

  // captureImage() {
  //   const videoElement = this.video.nativeElement;
  //   const canvasElement = this.canvas.nativeElement;
  //   const context = canvasElement.getContext('2d');
  //   canvasElement.width = videoElement.videoWidth;
  //   canvasElement.height = videoElement.videoHeight;
  //   context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
  //   this.capturedImage = canvasElement.toDataURL('image/png');
  // }

  async startCamera() {
    const result = await this.contratosService.requestCameraPermission();
    this.permissaoCamera = result.permitido;
    if (result.permitido && result.stream) {
      this.stream = result.stream;
      if (this.video) {
        this.video.nativeElement.srcObject = this.stream;
      }
    }
  }

  captureImage() {
    const videoElement = this.video.nativeElement;
    const canvasElement = this.canvas.nativeElement;
    const context = canvasElement.getContext("2d");
    canvasElement.width = videoElement.videoWidth;
    canvasElement.height = videoElement.videoHeight;
    context.drawImage(
      videoElement,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );
    this.capturedImage = canvasElement.toDataURL("image/png");

    // Substituir a visualização da câmera pela imagem capturada
    this.photoURL = this.capturedImage;
    this.photoConfirmed = true;

    // Parar a câmera
    this.stopCamera();

    // Atualizar o botão para "Tirar outra foto"
    this.updateAddPhotoButton();
  }

  stopCamera() {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
    }
  }

  confirmPhoto() {
    this.photoURL = this.capturedImage;
    this.photoConfirmed = true;
    this.modalService.dismissAll();
    document.querySelectorAll('.modal-backdrop').forEach(el => el.remove());
    this.updateAddPhotoButton(); // Atualiza o botão após confirmação
  }

retakePhoto() {
  this.capturedImage = '';
  this.photoConfirmed = false;
  this.startCamera();
  this.updateAddPhotoButton(); // Atualiza o botão após retomar a foto
}

  closeInstructionsModal() {
    const instructionsModal = document.getElementById("staticBackdrop");
    if (instructionsModal) {
      instructionsModal.classList.remove("show");
      instructionsModal.style.display = "none";
      document.body.classList.remove("modal-open");
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.remove();
      }
    }
  }

  openSelfieModal() {
    const selfieModal = document.getElementById("Selfie");
    if (selfieModal) {
      selfieModal.classList.add("show");
      selfieModal.style.display = "block";
      document.body.classList.add("modal-open");
      const backdrop = document.createElement("div");
      backdrop.className = "modal-backdrop fade show";
      document.body.appendChild(backdrop);
    }
  }

  private closeModalById(modalId: string) {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      modalElement.classList.remove("show");
      modalElement.style.display = "none";
      document.body.classList.remove("modal-open");
      $('.modal-backdrop').remove();
    }
  }

  private showModalById(modalId: string) {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      modalElement.classList.add("show");
      modalElement.style.display = "block";
      document.body.classList.add("modal-open");
    }
  }

  fecharModal() {
    this.modalService.dismissAll();
  }

  closeSelfieModal() {
    const selfieModal: HTMLElement | null = document.getElementById("Selfie");
    if (selfieModal) {
      selfieModal.classList.remove("show");
      selfieModal.setAttribute("aria-hidden", "true");
      selfieModal.setAttribute("style", "display: none");
      document.body.classList.remove("modal-open");
      const modalBackdrop = document.querySelector(".modal-backdrop");
      if (modalBackdrop) {
        modalBackdrop.remove();
      }
    }
  }

  updateAddPhotoButton() {
    const addPhotoButton = document.getElementById('addPhotoButton');
    if (addPhotoButton) {
      if (this.photoConfirmed) {
        addPhotoButton.style.display = "none";
      } else {
        addPhotoButton.style.display = "block";
      }
    }
  }

}
