import { Moeda } from "../gerenciamento/moeda.model";

export class Laminas {
  public Id: number;
  public Nome: String;
  public Formato: String;
  public URL: String;
  public Validade: String;
  public Deletado: boolean;
  public DeletadoPor: String;
  public DeletadoEm: String;
  public Template: boolean = false;
  public CorTemplate: string = "";
}

export class ItemLamina{
  AereoId: number;
  HotelId: number;
  Servicos: ItemServico[];
  Pagamentos: PagamentoLamina;
  ImagemId: number;
  TemplateId: number;
  UtilizarLogoAgencia: boolean = true;
  FraseOpcional: string = "";
  NomeHotel: string = "";
  Traducao: string = "pt";
  ImagemURL: string = "";
  buscaApi: boolean = true;
  AdicionaTaxas : boolean = false;
  Story : boolean = true;

}
export class ItemServico{
  ServicoId: number;
  NomeServico: string = "";
}

export class PagamentoLamina{
  FormaPagamento:any;
  CondicaoPagamento:any;
  CambioPagamento:Moeda;
  SimboloCambio: any;
  ValorAereo: number = 0;
  ValorTaxasAereo: number = 0;
  ValorHotel: number = 0;
  ValorServico: number = 0;
  ValorTotal: number = 0;
  Coeficiente: number;
  Parcelas: any;
  ValorParcelas: any;
  QntdAdtAereo: number;
  QntdAdtHotel: number;
  QntdAdtServico: number;
  ValorPorPessoa: Boolean = true;
}
export class Steps {
  FirstStep: boolean = false;
  SecondStep: boolean = false;
  ThirdStep: boolean = false;
  FourthStep: boolean = false;
}
