import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../services/login.service';

import { ParametroService } from '../../services/configuracao/parametro.service';
import { URLSITE, API, THEME, USA_CADASTROAGENCIA } from '../../app.config';

import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'ngx-login',
    styleUrls: ['./login.component.scss'],
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  protected options: {};
  redirectDelay: number;
  showMessages: any;
  strategy: string;
  errors: string[];
  messages: string[];
  valorParametro: any;
  carregando: boolean = false;
  CADASTROAGENCIA: boolean = USA_CADASTROAGENCIA;

    public user: any = { email: '', password: '' };
    submitted: boolean = false;
    rememberMe: boolean;

    verRecuperarSenha = false;
    verLogin = true;
    emailSenha = ''
    enviado = false;
    successSenha: any = null;
    errorSenha: any = null;
    Sair = false;
    Cancelar = true;

    Apenas_FRT = THEME.includes("FRT");
    BlackFRT = THEME.includes("FRT_BLACK");
    Arkbeds = THEME.includes("ARKBEDS");

    TipoTelaLogin = THEME;

    urlSite = URLSITE;
    enderecoapi = API;

    Lang: string = "pt";
    Email: string = "Email";
    Senha: string = "Senha";
    FraseHome: string = "Entrar";
    RecuperarSenha: string = "Recuperação de senha"

    constructor(
        private router: Router,
        private loginService: LoginService,
        private parametroService: ParametroService,
        private route: ActivatedRoute,
        private translate: TranslateService,
    ) {
    }

    ngOnInit() {
        this.setTranslation();
        this.loginService.isLoggedIn();
    }

    setTranslation() {
        switch (this.route.snapshot.params.lang) {
            case "pt":
                this.translate.setDefaultLang("pt");
                this.Email = "Email";
                this.Senha = "Senha";
                this.FraseHome = "Bem-vindo";
                this.RecuperarSenha = "Recuperação de senha";
                break;

            case "en":
                this.translate.setDefaultLang("en");
                this.Email = "Email";
                this.Senha = "Password";
                this.FraseHome = "Welcome";
                this.RecuperarSenha = "Password recovery";
                break;

            case "es":
                this.translate.setDefaultLang("es");
                this.Email = "Correo electrónico";
                this.Senha = "Contrasenã";
                this.FraseHome = "Bienvenido";
                this.RecuperarSenha = "Recuperación de contraseña";
                break;

            default:
                this.translate.setDefaultLang("pt");
                this.Email = "Email";
                this.Senha = "Senha";
                this.Lang = "pt";
                this.FraseHome = "Bem-vindo";
                this.RecuperarSenha = "Recuperação de senha";
                break;
        }
    }

    getConfigValue(key: string): any {
    }

    login() {
        this.errors = [];
        this.carregando = true;
        this.loginService.logar(this.user.email, this.user.password).subscribe(
            () => {},
            erro => {
                if (erro.status == 0) {
                    this.errors.push('O servidor não respondeu, tente novamente mais tarde.');
                    console.log(erro);
                } else {
                    if (erro.error && erro.error.error_description) {
                        this.errors.push(erro.error.error_description);
                    } else if (erro.error) {
                        this.errors.push(erro.error);
                    } else {
                        this.errors.push(erro);
                    }
                }
                this.user.password = '';
                this.carregando = false;
            },
        );
    }

    cadastrarAgencia() {
        this.router.navigate([`/sign-up`]);
    }

    clicou = true;
    toggleRecuperarSenha(event) {
        if (event)
            event.preventDefault();
        this.verRecuperarSenha = !this.verRecuperarSenha;
        this.verLogin = !this.verLogin;
        this.clicou = !this.clicou;
        this.errors = [];
    }

    recuperarSenha(event) {
        debugger;
        if (event)
            event.preventDefault();
        this.successSenha = null
        this.errorSenha = null;
        this.loginService.recuperarSenha({ Email: this.emailSenha }).subscribe(
            dados => {
                this.enviado = false;
                this.successSenha = 'Senha alterada com sucesso! Verifique seu email';
                this.Sair = true;
                this.Cancelar = false;

            },
            retorno => {
                if (retorno.status == 0) {
                    this.errorSenha = ['O servidor não respondeu, tente novamente mais tarde.'];
                } else {
                    this.errorSenha = [retorno.error.mensagem];
                }
            },
        );

    }

    buscarParametro() {
        this.parametroService.BuscarParametroPorChave('tela_inicial').subscribe(
            dados => {
                this.valorParametro = dados
                this.direcionamentoDeLogin(dados);
            },
            errors => {
                console.log(errors);
            }
        );
    }

    direcionamentoDeLogin(parametro) {

        if (parametro == "Buscador") {
            this.router.navigate(['/turismo/busca']);
        }
        else {
            this.router.navigate(['/meus-orcamentos']);
        }
    }

}
