import { Component, ElementRef, OnInit, ViewChildren, QueryList, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { interval, Subscription } from "rxjs";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, take } from "rxjs/operators";

import { LoginService } from "../../services";
import { Location } from "@angular/common";

@Component({
    selector: 'two-factor-auth',
    templateUrl: './two-factor-auth.component.html',
    styleUrls: ['./two-factor-auth.component.scss']
})
export class TwoFactorAuthComponent implements OnInit, OnDestroy {
    @ViewChildren('inputRef') inputRefs!: QueryList<ElementRef>;

    codeArray: string[] = ["", "", "", "", "", ""];
    mensagemErro: string = "";
    previousUrl: string = '';
    // carregando: boolean = false;

    constructor(
        private cdr: ChangeDetectorRef,
        private loginService: LoginService,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        history.pushState(null, null, window.location.href);
        window.onpopstate = () => {
            history.pushState(null, null, window.location.href);
        };
    }

    trackByIndex(index: number, item: any): number {
        return index; // Retorna o índice como identificador único
    }

    onInput(event: Event, index: number) {
        const target = event.target as HTMLInputElement;
        const value = target.value;

        // Apenas permita números e apenas um dígito
        if (value && !isNaN(Number(value)) && value.length === 1) {
            this.codeArray[index] = value; // Atualiza o valor no array

            // Move o foco para o próximo input se ainda houver mais inputs
            if (index < this.inputRefs.length - 1) {
                this.inputRefs.toArray()[index + 1].nativeElement.focus();
                this.cdr.detectChanges();
            }

            if (index === this.inputRefs.length - 1) {
                this.enviarCodigo();
            }
        } else {
            // Limpa o input se o valor não for válido
            this.codeArray[index] = '';
            target.value = '';
            this.cdr.detectChanges();
        }
    }

    onKeydown(event: KeyboardEvent, index: number) {
        const target = event.target as HTMLInputElement;

        // Se a tecla Backspace for pressionada e o input estiver vazio, vai para o input anterior
        if (event.key === 'Backspace' && !target.value && index > 0) {
            const prevInput = this.inputRefs.toArray()[index - 1].nativeElement;
            prevInput.focus();
        }
    }

    onPaste(event: ClipboardEvent) {
        // Obtém os dados do clipboard
        const clipboardData = event.clipboardData;
        const pastedText = clipboardData.getData('text') || '';

        // Verifica se o texto colado contém apenas números
        if (!/^\d+$/.test(pastedText)) {
            event.preventDefault();
            return;
        }

        // Preenche os inputs com os números colados
        for (let i = 0; i < this.codeArray.length && i < pastedText.length; i++) {
            this.codeArray[i] = pastedText[i];

            // Atualiza o valor do input e move o foco para o próximo
            this.inputRefs.toArray()[i].nativeElement.value = pastedText[i];

            if (i < this.inputRefs.length - 1) {
                this.inputRefs.toArray()[i + 1].nativeElement.focus();
            }
        }

        // Evita o comportamento padrão do evento de colar
        if (this.codeArray.length === pastedText.length) {
            this.enviarCodigo();
        }
        event.preventDefault();
    }

    enviarCodigo() {
        this.loginService.verificarCodigoAutenticacao(this.codeArray.join("")).subscribe(
            (dados) => {
                if (dados === "Autenticado com Sucesso") {
                    this.loginService.buscarDadosUsuario(this.loginService.getTempUser());
                    this.router.navigate(['/']);
                } else {
                    this.mensagemErro = dados;
                }
            },
            (err) => {
                this.mensagemErro = "Erro ao verificar o código";
                console.error('Error verifying code:', err);
            }
        );
    }

    refazerLogin() {
        this.loginService.clearTempUser();
        this.loginService.clearFingerPrint();

        this.router.navigate(['/'], {relativeTo: this.route, skipLocationChange: true});
    }

    ngOnDestroy(): void {
        // Limpa o código ao sair do componente
        this.codeArray = ["", "", "", "", "", ""];
        this.loginService.clearTempUser();
    }
}
