import { Cidade } from "../endereco.model";
import { Notificacao } from "./notificacao.model";
import { WebService } from "./webservice.model";

export class Evento {
    public Id: number;
    public ImagemPrincipal: string;
    public Titulo: string;
    public Localizacao: string;
    public CheckIn: any;
    public CheckOut: any;
    public Sobre: string;
    public Ativo: boolean = true;
    public InformacoesGerais: string;
    public Destaque: boolean = false;
    public Cidade: any = { Nome: "" };
    public CidadeId: number;
    public Programacao: Programacao[];
    public Pergunta: EventoPergunta[];
    public Noticia: EventoNoticia[];
    public Inscricao: EventoInscricao[];
    public EventoImagens: EventoImagem[];
    public EventoHoteis: EventoHotel[];
}
export class Programacao {
    public Id: number;
    public EventoId: number;
    public Titulo: string;
    public Cronograma: Cronograma[];
}

export class EventoImagem {
    public Id: number;
    public Imagem: string;
    public EventoId: number;
    public Evento: Evento;
}
export class Cronograma {
    public Id: number;
    public ProgramacaoId: number;
    public Titulo: string;
    public DataHoraInicio: string;
    public DataHoraFim: string;
    public Descritivo: string;
    public Imagens = [];
}

export class EventoPergunta {
    public Id: number;
    public Descritivo: string;
    public TipoPergunta: number;
    public Ordem: number;
    public EventoId: number;
}

export class EventoNoticia extends Notificacao {
    public EventoId: number;
}
export class EventoInscricao {
  public Id: number;
  public EventoId: number;
  public UsuarioId: number;
  public Usuario: any = { Nome: "" };
  public Alteracoes: any;
}

export class EventoHotel{
    public Id: number;
    public Evento: Evento;
    public EventoId: number;
    public CodigoFornecedor: any;
    public CodigoTarifa: any;
    public WebServiceId: any;
    public WebService: any = { Descricao: "" };
}
