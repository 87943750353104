<section class="container col-12 bg-light">
  <section class="cadastrar box-card p-0 p-md-4">

    <div class="mt-md-3 mt-3 pt-4">
      <h1 class="text-primary text-info text-center" *ngIf="ambiente != 'AGENTGO'">CADASTRO DE AGÊNCIAS</h1>
      <h1 class="text-primary text-info text-center" *ngIf="ambiente == 'AGENTGO'">CADASTRO DE AGENTES</h1>
    </div>

    <div class="wrapper p-md-4 p-4 col-12">

      <form [formGroup]="form" (ngSubmit)="enviarCadastro()">

        <div class="col-12">
          <div>
            <h2 style="color: #36a9e1; padding-bottom: 20px; text-align: center;">
              {{"Identificação" | translate}}
            </h2>
          </div>

          <div class="form">
            <div class="inputfield">
              <label for="razaosocial">{{"Razão Social" | translate}}:</label>
              <input type="text" class="input" formControlName="razaoSocial" minlength="10" required>
              <div *ngIf="form.get('razaoSocial').invalid && form.get('razaoSocial').touched" class="text-danger">
                <small
                  *ngIf="form.get('razaoSocial').hasError('required')">{{"Razão Social é obrigatório" | translate}}.</small>
              </div>
            </div>


            <div class="inputfield">
              <label for="nomefantasia">{{"Nome Fantasia" | translate}}:</label>
              <input type="text" class="input" formControlName="nomeFantasia" maxlength="200" required>
              <div *ngIf="form.get('nomeFantasia').invalid && form.get('nomeFantasia').touched" class="text-danger">
                <small
                  *ngIf="form.get('nomeFantasia').hasError('required')">{{"Nome Fantasia é obrigatório" | translate}}.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="cnpj">CNPJ:</label>
              <input type="text" class="input" modelo="00.000.000/0000-00" placeholder="Exemplo: 00.000.000/0000-00"
                formControlName="cnpjAgencia" mask="00.000.000/0000-00" (change)="aplicarMascaraCNPJ()" maxlength="18"
                required>
              <div *ngIf="form.get('cnpjAgencia').invalid && form.get('cnpjAgencia').touched" class="text-danger">
                <small
                  *ngIf="form.get('cnpjAgencia').hasError('required')">{{"CNPJ é obrigatório" | translate}}.</small>
                <small
                  *ngIf="form.get('cnpjAgencia').hasError('pattern')">{{"Formato do CNPJ inválido" | translate}}.</small>
              </div>
            </div>


            <div class="inputfield" *ngIf="ambiente != 'AGENTGO'">
              <label for="identificacaoestadual">{{"Inscrição Estadual" | translate}}:</label>
              <input type="text" class="input" placeholder="" formControlName="identificacaoEstadual" required>
              <div *ngIf="form.get('identificacaoEstadual').invalid && form.get('identificacaoEstadual').touched"
                class="text-danger">
                <small
                  *ngIf="form.get('identificacaoEstadual').hasError('required')">{{"Identificação Estadual é obrigatório" | translate}}.</small>
              </div>
            </div>


            <div class="inputfield" *ngIf="ambiente != 'AGENTGO'">
              <label for="identificacaomunicipal">{{"Inscrição Municipal" | translate}}:</label>
              <input type="text" class="input" placeholder="" formControlName="identificacaoMunicipal" required>
              <div *ngIf="form.get('identificacaoMunicipal').invalid && form.get('identificacaoMunicipal').touched"
                class="text-danger">
                <small
                  *ngIf="form.get('identificacaoMunicipal').hasError('required')">{{"Identificação Municipal é obrigatório" | translate}}.</small>
              </div>
            </div>


            <div class="inputfield">
              <label for="embratur">Embratur:</label>
              <input type="text" class="input" placeholder="" formControlName="embratur" required>
              <div *ngIf="form.get('embratur').invalid && form.get('embratur').touched" class="text-danger">
                <small
                  *ngIf="form.get('embratur').hasError('required')">{{"Embratur é obrigatório" | translate}}.</small>
              </div>
            </div>

            <div class="inputfield">
              <label>{{"Idioma" | translate}}</label>
              <div class="custom_select">
                <select formControlName="idioma" required>
                  <option value="pt" selected>{{"Português" | translate}}</option>
                  <option value="en">{{"Inglês" | translate}}</option>
                  <option value="es">{{"Espanhol" | translate}}</option>
                </select>
              </div>
              <div *ngIf="form.get('idioma').invalid && form.get('idioma').touched" class="text-danger">
                <small *ngIf="form.get('idioma').hasError('required')">{{"Idioma é obrigatório" | translate}}.</small>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <h2 style="color: #36a9e1; padding-bottom: 20px; padding-top: 25px; text-align: center;">
            {{"Contatos" | translate}}</h2>

          <div class="form">
            <div class="inputfield">
              <label for="telefoneprincipal">{{"Telefone Principal" | translate}}</label>
              <input type="text" class="input" placeholder="(99) 9999 - 9999" formControlName="telefonePrincipal"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57" required>
              <div *ngIf="form.get('telefonePrincipal').invalid && form.get('telefonePrincipal').touched"
                class="text-danger">
                <small
                  *ngIf="form.get('telefonePrincipal').hasError('required')">{{"Telefone Principal é obrigatório" | translate}}.</small>
                <small
                  *ngIf="form.get('telefonePrincipal').hasError('pattern')">{{"Telefone Principal é inválido" | translate}}.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="telefonesecundario">{{"Telefone Secundário" | translate}} - Opcional</label>
              <input type="text" class="input" placeholder="(99) 9999 - 9999" formControlName="telefoneSecundario"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57">
              <div *ngIf="form.get('telefoneSecundario').invalid && form.get('telefoneSecundario').touched"
                class="text-danger">
                <small
                  *ngIf="form.get('telefoneSecundario').hasError('pattern')">{{"Telefone Secundário é inválido" | translate}}.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="emailagencia">{{"E-mail" | translate}}:</label>
              <input type="email" formControlName="emailAgencia" placeholder="Exemplo: email@email.com" class="input"
                required>
              <div *ngIf="form.get('emailAgencia').invalid && form.get('emailAgencia').touched" class="text-danger">
                <small *ngIf="form.get('emailAgencia').hasError('email')">{{"E-mail é inválido" | translate}}.</small>
                <small
                  *ngIf="form.get('emailAgencia').hasError('required')">{{"E-mail é obrigatório" | translate}}</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="website">{{"Website - Opcional" | translate}}</label>
              <input type="text" class="input" placeholder="seusite.com.br" required formControlName="website" required>
            </div>
          </div>
        </div>


        <div class="col-12">
          <h2 style="color: #36a9e1; padding-bottom: 20px; padding-top: 25px; text-align: center;">
            {{"Endereço da Agência" | translate}}
          </h2>

          <div class="form">
            <div class="inputfield">
              <label for="cep">{{"CEP" | translate}}:</label>
              <input type="text" class="input" maxlength="9" formControlName="cep" required>
              <div *ngIf="form.get('cep').invalid && form.get('cep').touched" class="text-danger">
                <small *ngIf="form.get('cep').hasError('required')">{{"CEP" | translate}} é
                  obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="logradouro">{{"Logradouro" | translate}}:</label>
              <input type="text" class="input" formControlName="logradouro" required>
              <div *ngIf="form.get('logradouro').invalid && form.get('logradouro').touched" class="text-danger">
                <small *ngIf="form.get('logradouro').hasError('required')">{{"Logradouro" | translate}}é
                  obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="numero">{{"Número" | translate}}:</label>
              <input type="text" class="input" formControlName="numero" maxlength="6" required>
              <div *ngIf="form.get('numero').invalid && form.get('numero').touched" class="text-danger">
                <small *ngIf="form.get('numero').hasError('required')">{{"Número" | translate}} é
                  obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="bairro">{{"Bairro" | translate}}:</label>
              <input type="text" class="input" formControlName="bairro" required>
              <div *ngIf="form.get('bairro').invalid && form.get('bairro').touched" class="text-danger">
                <small *ngIf="form.get('bairro').hasError('required')">{{"Bairro" | translate}} é
                  obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="cidade">{{"Cidade" | translate}}:</label>
              <input type="text" class="input" formControlName="cidade" required>
              <div *ngIf="form.get('cidade').invalid && form.get('cidade').touched" class="text-danger">
                <small *ngIf="form.get('cidade').hasError('required')">{{"Cidade" | translate}} é
                  obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="estado">{{"Estado" | translate}}:</label>
              <input type="text" class="input" formControlName="estado" required>
              <div *ngIf="form.get('estado').invalid && form.get('estado').touched" class="text-danger">
                <small *ngIf="form.get('estado').hasError('required')">{{"Estado" | translate}} é
                  obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="complemento">{{"Complemento" | translate}}:</label>
              <input type="text" class="input" formControlName="complemento" required>
              <div *ngIf="form.get('complemento').invalid && form.get('complemento').touched" class="text-danger">
                <small *ngIf="form.get('complemento').hasError('required')">{{"Complemento" |
                                    translate}} é
                  obrigatório.</small>
              </div>
            </div>
          </div>
        </div>


        <div class="col-12">
          <h2 style="color: #36a9e1; padding-bottom: 20px; padding-top: 25px; text-align: center;">
            {{"Dados bancários" | translate}}
          </h2>

          <div class="form">
            <div class="inputfield">
              <label for="nomebanco">{{"Nome do Banco" | translate}}:</label>
              <input type="text" class="input" formControlName="nomeBanco" required>
              <div *ngIf="form.get('nomeBanco').invalid && form.get('nomeBanco').touched" class="text-danger">
                <small *ngIf="form.get('nomeBanco').hasError('required')">{{"Nome do Banco" |
                                    translate}} é
                  obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="numerobanco">{{"Número do Banco" | translate}}:</label>
              <input type="text" class="input" placeholder=""
                onkeypress="return event.charCode >= 48 && event.charCode <= 57" formControlName="numeroBanco" required>
              <div *ngIf="form.get('numeroBanco').invalid && form.get('numeroBanco').touched" class="text-danger">
                <small *ngIf="form.get('numeroBanco').hasError('required')">{{"Número do Banco" |
                                    translate}} é
                  obrigatório.</small>
              </div>
            </div>


            <div class="inputfield">
              <label for="agenciabanco">{{"Agência" | translate}}:</label>
              <input type="text" class="input" placeholder=""
                onkeypress="return event.charCode >= 48 && event.charCode <= 57" formControlName="agenciaBanco"
                required>
              <div *ngIf="form.get('agenciaBanco').invalid && form.get('agenciaBanco').touched" class="text-danger">
                <small *ngIf="form.get('agenciaBanco').hasError('required')">Agência do Banco é
                  obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="numeroconta">{{"Número da Conta" | translate}}:</label>
              <input type="text" class="input" placeholder=""
                onkeypress="return event.charCode >= 48 && event.charCode <= 57" formControlName="numeroConta"
                required><br>
              <div *ngIf="form.get('numeroConta').invalid && form.get('numeroConta').touched" class="text-danger">
                <small *ngIf="form.get('numeroConta').hasError('required')">{{"Número da Conta" |
                                    translate}} é
                  obrigatório.</small>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <h2 style="color: #36a9e1; padding-bottom: 20px; padding-top: 25px; text-align: center;">
            {{"Documentos Da Agência" | translate}}
          </h2>

          <div class="form">
            <h1 style="text-align: center; width: 100%; margin-bottom: 15px;">{{"Contrato" | translate}}
            </h1>
            <div class="inputfield">
              <label for="contratosocial">{{"Social / Microempreendedor" | translate}}</label>
              <input type="file" class="input" (ngModelOptions)="{standalone: true}"
                (change)="fileInput($event, 'contratoSocial')" style="text-align: center;" required>
              <div *ngIf="form.get('contratoSocial').invalid && form.get('contratoSocial').touched" class="text-danger">
                <small *ngIf="form.get('contratoSocial').hasError('required')">Contrato Social é obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="cartaocpj">{{"Cartão CNPJ" | translate}}</label>
              <input type="file" class="input" (ngModelOptions)="{standalone: true}"
                (change)="fileInput($event, 'cartaoCPJ')" id="cartaocpj">
              <div *ngIf="form.get('cartaoCPJ').invalid && form.get('cartaoCPJ').touched" class="text-danger">
                <small
                  *ngIf="form.get('cartaoCPJ').hasError('required')">{{"Cartão CNPJ é obrigatório" | translate}}.</small>
              </div>
            </div>

            <div class="inputfield">
              <label
                for="comprovanteenderecocomercial">{{"Comprovante de Endereço Comercial - Dos ultimos 90 dias." | translate}}</label>
              <input type="file" class="input" (ngModelOptions)="{standalone: true}"
                (change)="fileInput($event, 'comprovanteEndereco')" id="comprovanteEndereco">
              <div *ngIf="form.get('comprovanteEndereco').invalid && form.get('comprovanteEndereco').touched"
                class="text-danger">
                <small *ngIf="form.get('comprovanteEndereco').hasError('required')">Comprovante de Endereço é
                  obrigatório.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="identificacaobancario">{{"Documento do Banco Identificando a Conta Bancária" |
                                translate}}</label>
              <input type="file" class="input" (ngModelOptions)="{standalone: true}"
                (change)="fileInput($event, 'identificacaoBancaria')" id="identificacaobancario">
              <div *ngIf="form.get('identificacaoBancaria').invalid && form.get('identificacaoBancaria').touched"
                class="text-danger">
                <small
                  *ngIf="form.get('identificacaoBancaria').hasError('required')">{{"Documento do Banco Identificando a Conta Bancária é obrigatório" | translate}}.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="doccadastur">{{"Certificado Cadastur" | translate}}</label>
              <input type="file" class="input" (change)="fileInput($event, 'doccadastur')"
                (ngModelOptions)="{standalone: true}" id="doccadastur">
              <div *ngIf="form.get('doccadastur').invalid && form.get('doccadastur').touched" class="text-danger">
                <small
                  *ngIf="form.get('doccadastur').hasError('required')">{{"Certificado Cadastur é obrigatório" | translate}}.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="docembratur">{{"Certificado Embratur" | translate}}</label>
              <input type="file" class="input" (change)="fileInput($event, 'docembratur')"
                (ngModelOptions)="{standalone: true}" id="docembratur">
              <div *ngIf="form.get('docembratur').invalid && form.get('docembratur').touched" class="text-danger">
                <small
                  *ngIf="form.get('docembratur').hasError('required')">{{"Certificado Embratur é obrigatório" | translate}}.</small>
              </div>
            </div>

            <div class="inputfield">
              <label for="logoempresa">{{"Logo da Empresa (PNG OU JPG)" | translate}}</label>
              <input type="file" (change)="fileInput($event, 'logoempresa')" (ngModelOptions)="{standalone: true}"
                accept="image/*" class="input" id="logoempresa" accept="image/*">
              <div *ngIf="form.get('logoempresa').invalid && form.get('logoempresa').touched" class="text-danger">
                <small
                  *ngIf="form.get('logoempresa').hasError('required')">{{"Logo da Empresa (PNG OU JPG) é obrigatório" | translate}}.</small>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <h1 class="text-primary"
            style="font-size: 25px; padding-bottom: 20px; padding-top: 25px; text-align: center;">
            {{"Informações dos Sócios" | translate}}
          </h1>

          <div class="form">
            <h2 style="text-align: center; width: 100%; margin-bottom: 10px;">{{"Quantidade de Sócios" | translate}}
            </h2>
            <div class="inputfield d-flex flex-column flex-md-row aling-items-center gap-3">
              <input type="number" class="input" [(ngModel)]="numeroSocios" [ngModelOptions]="{standalone: true}"
                min="1" max="3" maxlength="1" [disabled]="socioCadastroAgencia.length > 0 ? true : false">
              <button class="btn btn-primary ml-4 w-100" *ngIf="socioCadastroAgencia.length == 0"
                (click)="confirmaQtdeSocios()">{{"Confirmar Número de Sócios" | translate}}</button>

              <button class="btn btn-primary ml-4 w-100" *ngIf="socioCadastroAgencia.length > 0"
                (click)="limpaNumSocios()">
                {{"Mudar Quantidade de Sócios" | translate}}</button>
            </div>


            <div id="sociosdiv" *ngFor="let socio of socioCadastroAgencia; let i = index;">
              <div id="socio" style="margin-bottom: 15px">
                <h4 style="color: #36a9e1; padding-bottom: 10px; padding-top: 10px; text-align: center;">
                  Sócio - {{i + 1}}</h4>
                <div class="inputfield">
                  <label for="nomesocio">{{"Nome Completo" | translate}}</label>
                  <input type="text" class="input" id="nomesocio" [(ngModel)]="socio.nome"
                    [ngModelOptions]="{standalone: true}" required>
                  <div *ngIf="mostrarMensagemErro && !socio.nome" class="text-danger">
                    <small>{{"Nome Completo" | translate}} é obrigatório.</small>
                  </div>
                </div>
                <div class="inputfield">
                  <label for="cpfsocio">{{"CPF" | translate}}</label>
                  <input type="text" class="input" id="cpfsocio" maxlength="14" [(ngModel)]="socio.cpf"
                    (ngModelChange)="aplicarMascaraCPF(socio)" [ngModelOptions]="{standalone: true}" required>
                  <div *ngIf="mostrarMensagemErro && !socio.cpf" class="text-danger">
                    <small>{{"CPF" | translate}} é obrigatório.</small>
                  </div>
                </div>
                <div class="inputfield">
                  <label for="rgsocio">{{"RG" | translate}}</label>
                  <input type="text" class="input" id="rgsocio" maxlength="12" [(ngModel)]="socio.rg"
                    (ngModelChange)="aplicarMascaraRG(socio)" [ngModelOptions]="{standalone: true}" required>
                  <div *ngIf="mostrarMensagemErro && !socio.cpf" class="text-danger">
                    <small>{{"RG" | translate}} é obrigatório.</small>
                  </div>
                </div>
                <div class="inputfield">
                  <label for="telefonesocio">{{"Telefone" | translate}}</label>
                  <input type="text" class="input" id="telefonesocio" maxlength="15" [(ngModel)]="socio.telefone"
                    (ngModelChange)="aplicarMascaraTelefoneSocios()" [ngModelOptions]="{standalone: true}">
                  <div *ngIf="mostrarMensagemErro && !socio.telefone" class="text-danger">
                    <small>{{"Telefone" | translate}} é obrigatório.</small>
                  </div>
                </div>

                <div class="inputfield">
                  <label for="emailsocio">E-mail</label>
                  <input type="text" class="input" id="emailsocio" [(ngModel)]="socio.email"
                    [ngModelOptions]="{standalone: true}">
                  <div *ngIf="mostrarMensagemErro && !socio.email" class="text-danger">
                    <small>E-mail é obrigatório.</small>
                  </div>
                </div>

                <div class="inputfield">
                  <label for="enderecosocio">{{"Endereço" | translate}}</label>
                  <input type="text" class="input" id="enderecosocio" [(ngModel)]="socio.endereco"
                    [ngModelOptions]="{standalone: true}" required>
                  <div *ngIf="mostrarMensagemErro && !socio.endereco" class="text-danger">
                    <small>{{"Endereço" | translate}} é obrigatório.</small>
                  </div>
                </div>

                <div class="inputfield">
                  <label for="rgcnhSocio{{ i }}">{{"RG/CNH" | translate}}</label>
                  <input type="file" class="input" (change)="fileInput($event, 'rgcnh', socio)" required>
                  <div *ngIf="mostrarMensagemErro && !socio.rgcnh" class="text-danger">
                    <small>{{"RG/CNH" | translate}} é obrigatório.</small>
                  </div>
                </div>

                <div class="inputfield">
                  <label for="comprovanteResidenciaSocio{{ i }}">{{"Comprovante de Residência do
                                        sócio" |
                                        translate}}</label>
                  <input type="file" class="input" (change)="fileInput($event, 'comprovanteResidencia', socio)"
                    required>
                  <div *ngIf="mostrarMensagemErro && !socio.comprovanteResidencia" class="text-danger">
                    <small>{{"Comprovante de Residência do sócio" | translate}} é
                      obrigatório.</small>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="inputfield" *ngIf="ambiente == 'AGENTGO'">
          <label>TERMO DE ADESÃO AO SERVIÇO AGENTGO</label>
          <div class="scrollable-text">
            <pre>
          TERMO DE ADESÃO AO SERVIÇO AGENTGO

          1. DAS PARTES

          Este Termo de Adesão é celebrado entre:

          AgentGo Inova Simples (I.S.) inscrita no CNPJ sob o nº05.047.201/0001-36 com sede em Foz do Iguaçu, PR Av.
          Brasil,1346/205, CEP 85851-000 doravante denominada simplesmente “AgentGo”;

          E

          Usuário, sendo este o agente de turismo, MEI, empresa ou agência de turismo inscrita no CNPJ sob o nº
          [número], com sede em [endereço completo], doravante denominado simplesmente “Usuário”.

          2. OBJETO

          O presente Termo tem por objeto regular as condições para adesão e uso, pelo Usuário, da plataforma digital
          AgentGo, a qual oferece funcionalidades para a criação, gestão e venda de pacotes turísticos, passagens,
          passeios, produtos de turismo em geral, incluindo a negociação e tratativas comerciais.

          3. DA ASSINATURA E REMUNERAÇÃO

          3.1. Assinatura Recorrente: O Usuário concorda em pagar à AgentGo uma assinatura mensal no valor de R$
          [valor], que será cobrada de forma recorrente através do meio de pagamento especificado durante o cadastro.

          3.2. Comissionamento sobre Vendas: Em contrapartida à utilização da plataforma, o Usuário receberá um
          percentual de comissão de 8% sobre o valor bruto de cada pacote turístico vendido por meio da plataforma
          AgentGo. O pagamento das comissões será realizado pela AgentGo conforme a forma de pagamento do cliente em xx
          até o [dia] do mês subsequente ao da realização das vendas.

          3.3. Reajustes e Alterações: O valor da assinatura poderá ser reajustado mediante notificação prévia ao
          Usuário, com antecedência mínima de 30 (trinta) dias.

          4. OBRIGAÇÕES DAS PARTES

          4.1. Obrigações da AgentGo:

          a) Disponibilizar a plataforma AgentGo ao Usuário, garantindo seu funcionamento e atualizações necessárias
          para o bom desempenho, exceto em casos de manutenção programada ou eventos de força maior;

          b) Prestar suporte técnico durante o horário comercial através dos canais de atendimento disponibilizados na
          plataforma;

          c) Gerenciar e efetuar o pagamento das comissões ao Usuário conforme estabelecido neste Termo.

          4.2. Obrigações do Usuário:

          a) Realizar o pagamento da assinatura mensal de forma pontual;

          b) Utilizar a plataforma de forma responsável e exclusivamente para os fins descritos neste Termo;

          c) Respeitar todas as normas e regulamentos aplicáveis, bem como as políticas e diretrizes definidas pela
          AgentGo;

          d) Inserir informações completas e verdadeiras sobre os pacotes turísticos oferecidos, sendo responsável por
          quaisquer danos decorrentes de informações incorretas ou enganosas.

          5. VIGÊNCIA E RESCISÃO

          5.1. Vigência: Este Termo entra em vigor na data de aceite pelo Usuário e permanece vigente por prazo
          indeterminado, enquanto a assinatura for mantida ativa.

          5.2. Rescisão:

          a) O Usuário poderá solicitar a rescisão deste Termo a qualquer momento, mediante notificação por escrito à
          AgentGo, com antecedência mínima de 30 (trinta) dias;

          b) AgentGo poderá rescindir este Termo, imediatamente e sem aviso prévio, caso o Usuário descumpra qualquer
          das obrigações previstas neste Termo ou utilize a plataforma de forma contrária às leis vigentes ou às normas
          da AgentGo.

          6. LIMITAÇÃO DE RESPONSABILIDADE

          AgentGo não se responsabiliza por quaisquer danos indiretos, lucros cessantes ou prejuízos financeiros
          sofridos pelo Usuário, em decorrência do uso inadequado da plataforma, interrupções de serviços ou quaisquer
          eventos alheios ao controle da AgentGo.

          7. PRIVACIDADE E CONFIDENCIALIDADE

          7.1. Privacidade dos Dados: AgentGo poderá coletar e armazenar informações do Usuário, em conformidade com a
          legislação aplicável e de acordo com a Política de Privacidade, disponível em [link].

          7.2. Confidencialidade: Ambas as partes se comprometem a manter em sigilo todas as informações confidenciais
          recebidas em decorrência deste Termo, não as divulgando a terceiros sem a devida autorização.

          8. ALTERAÇÕES DO TERMO

          AgentGo se reserva o direito de alterar este Termo a qualquer momento, mediante comunicação ao Usuário, com
          antecedência mínima de 30 (trinta) dias. A continuidade do uso da plataforma após tal notificação implicará na
          aceitação das novas condições.

          9. DISPOSIÇÕES GERAIS

          9.1. Integralidade: Este Termo constitui o acordo integral entre as partes em relação ao seu objeto,
          substituindo todos os entendimentos anteriores.

          9.2. Cessão: O Usuário não poderá ceder ou transferir este Termo sem o consentimento prévio por escrito da
          AgentGo.

          9.3. Foro: Para dirimir quaisquer dúvidas ou controvérsias oriundas deste Termo, as partes elegem o foro da
          comarca de [cidade], com renúncia expressa a qualquer outro, por mais privilegiado que seja.

          10. ACEITAÇÃO

          Ao clicar em “Eu Aceito” no momento do cadastro, o Usuário declara ter lido, entendido e aceitado todos os
          termos e condições descritos neste documento.

          AgentGo Tech Ltda.
            </pre>
          </div>
          <div class="inputfield">
            <label class="checkbox-label">
              <input type="checkbox" class="inputCheckbox" (change)="AlteraisChecked($event)"/>
              Aceito os termos e condições
            </label>
          </div>
        </div>



        <div class="mt-5 input-field d-flex flex-column jusitfy-content-center">
          <button class="btn btn-info text-white text-center w-100" style="font-size: 18px;" [disabled]="!isChecked" *ngIf="ambiente == 'AGENTGO'"
            (click)="enviarCadastro()">{{"Enviar Cadastro" | translate}}</button>

            <button class="btn btn-info text-white text-center w-100" style="font-size: 18px;" *ngIf="ambiente != 'AGENTGO'"
            (click)="enviarCadastro()">{{"Enviar Cadastro" | translate}}</button>
          <div *ngIf="mostrarMensagemErro" class="erro-mensagem">
            Não foi possível cadastrar a agência por ter um ou mais campos preenchidos incorretamente.

            <ul>
              <li *ngFor="let keyInvalid of getFormValidationErrors()">
                {{"Campo com erro" | translate}}: {{keyInvalid.field}} - {{"Mensagem de erro" | translate}}:
                {{keyInvalid.error}}
              </li>
            </ul>
          </div>

          <div *ngIf="mostrarMensagemSucesso" class="sucesso-mensagem">
            {{mensagemSucesso | translate}}
          </div>
        </div>
      </form>
    </div>
  </section>
</section>
