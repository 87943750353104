import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';

import { GERENCIAMENTO, ITENSPORPAGINA } from '../../app.config';
import { Notificacao } from '../../model';

@Injectable()
export class NotificacaoService {

    constructor(private http: HttpClient) { }
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    buscarPorParametro(param: string, pagina: Number): Observable<any> {
        this.headers.set("noloader", "t");

        if (param.length > 0) {
            return this.http.get<any>(`${GERENCIAMENTO}/api/Notificacao/list/${param.trim()}?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: this.headers });
        } else {
            return this.http.get<any>(`${GERENCIAMENTO}/api/Notificacao?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, { headers: this.headers });
        }
    }

    buscarPorId(id: number): Observable<any> {
        return this.http.get<any>(`${GERENCIAMENTO}/api/Notificacao/${id}?Pagina=1&ItensPorPagina=${ITENSPORPAGINA}`);
    }

    remover(id: Number): Observable<any> {
        return this.http.delete<any>(`${GERENCIAMENTO}/api/Notificacao/${id}`);
    }

    salvar(notificacao: Notificacao): Observable<any> {
        if (notificacao.Id > 0) {
            return this.http.put<any>(`${GERENCIAMENTO}/api/Notificacao/${notificacao.Id}`, notificacao);
        } else {
            return this.http.post<any>(`${GERENCIAMENTO}/api/Notificacao/`, notificacao);
        }
    }

    dispararNotificacao(id: number): Observable<boolean> {
        this.headers.set("noloader", "t");
        return this.http.get<boolean>(`${GERENCIAMENTO}/api/Notificacao/Disparar/${id}`, {headers: this.headers});
    }

}
