import { Cidade } from '../endereco.model';
import { Empresa } from './empresa.model';
import { Usuario } from '../usuario.model';
import { PerfilMarkup } from '../gerenciamento/markup.model';
import { WebService } from '../gerenciamento/webservice.model';
import { FormaPagamento } from '../gerenciamento/forma-pagamento.model';


export class Agencia {
  public Id: number;

  public RazaoSocial: String;
  public NomeFantasia: String;
  public CNPJ: String;
  public InscricaoEstadual: String;
  public InscricaoMunicipal: String;
  public EmpresaId: number;
  public Consultores: any[];
  public WebSite: any;
  public Email: any;
  public TelefonePrincipal: any;
  public TelefoneSecundario: any;
  public LimiteCredito: any;
  public SaldoCredito: any;
  public Comissao: any;
  public FormaPgtoGateway: any;
  public FormaPgtoFinanciamento: any;
  public NumMaxParcelas: any;
  public UsaOffline: any;
  public Idioma: any;
  public BonusAereo: any;
  public UsaContrato: any;
  public UsaPrazoCancelamentoWs: boolean = false;
  public IgnoraMarkUpFixoHotel: any;
  public IgnoraMarkUpFixoBloqueio: any;
  public Campanha: any;
  public IgnoraMarkupTaxas: any;
  public MoedaListagemId: any;
  public MoedaPagamentoId: any;
  public ListaMoedaFornecedor: any;
  public LiberaVoucherAutomatico: any;
  public Embratur: any;
  public CodigoERP: any;
  public Situacao: any;
  public DadosBancarios: string;
  public Observacoes: string;
  public ObservacoesSaldo: string;
  public Internacional: any;
  public Promotor: any;
  public TravaEmbarque: any;
  public DiasTravaEmbarque: any;
  public Latitude: string;

  public Longitude: string;

  public LimiteCartaoDeCredito: any;
  public SaldoCartaoDeCredito: any;
  public UsaSaldoCartaoDeCredito: any
  public LimiteConfirmacaoTerceiros: any;
  public SaldoConfirmacaoTerceiros: any;
  public UsaConfirmacaoTerceiros: any
  public UsaSaldoConfirmacaoTerceiros: any;
  public Visivel: any;

  public Cidade: any = { Nome: "" };
  public GrupoEconomico: any = { Nome: "" };
  public GrupoEconomicoId: any;
  public Logo: string;
  public Empresa: any = { NomeFantasia: "" };
  public AgenciaNome: any = { NomeFantasia: "" };
  public PerfilMarkup: any = { Descricao: "" };
  public PerfilMarkupId: number;
  public Comercial?: any = { Nome: "" };
  public Meta: number;
  public LogoApresentacao: any;
  public Excluidos: WebService[];

  public Cep: any;
  public Logradouro: any;
  public Numero: any;
  public Bairro: any;
  public Complemento: any;
  public Ativo: any;
  public Alteracoes: any;
  public FormasPagamento: any;
  public AgenciaPadrao: any;

  public FraseHome: string;
  public FraseRodape: string;
  public Instagram: string;
  public Facebook: string;
  public WhatsApp: string;
  public QuemSomos: string;
  public LogoWBFooter: string;

  // Periodo em exclusividade da agencia com o bloqueio
  public PeriodoExclusividadeAgencia: any;
  public PermiteFinanciamentoTerceiros: boolean = true;

  public UsaLoginSingleSignOn: boolean = false;
}

export class AgenciaFormaPagamento {
  Id: number;
  FormaPagamento: FormaPagamento = new FormaPagamento();
  MaxParcelas: number;
  Ativo: boolean;
  AgenciaId: number;
  FormaPagamentoId: number
}

export class Agencia_Integrador {
  public WebService?: any = { Descricao: '' };
}

export class AgenciaWB {
  Id: number;
  AgenciaId: number;
  Agencia: Agencia = new Agencia();
  Link: string;
  ImagemURL: string;
  ImagemURLMobile: string;
  BannerDeBaixo: boolean = false;
}

export class CadastroAgencia {
  Id: any;
  RazaoSocial: string;
  NomeFantasia: string;
  QuantidadeSocios: number;
  InscricaoEstadual: string;
  InscricaoMunicipal: string;
  Email: string;
  WebSite: string;
  TelefonePrincipal: string;
  TelefoneSecundario: string;
  CEP: string;
  Logradouro: string;
  Bairro: string;
  Estado: string;
  Numero: string;
  NomeBanco: string;
  NumeroBanco: string;
  AgenciaBanco: string;
  NumeroConta: string;
  AgenciaId: number = null;
  CNPJ: string;
  Complemento: string;
  Cidade: string;
  Embratur: string;
  Cadastur: string;
  Idioma: string;
  Extra: string;
  Extra1: string;
  DataAberturaEmpresa: string;
  Anexos: AnexosCadastro[] = [];
  Alteracoes: any;
  JustificativaAlteracao: string;
  Deletado: boolean;
  DeletadoPor: string;
  DeletadoEm: Date | null;
  Socios?: SocioAgenciaCadastro[] = [];
  Status: number;
}

export class SocioAgenciaCadastro {
  Id: any;
  NomeCompleto: string;
  CPF: string;
  RG: string;
  Endereco: string;
  Telefone: string;
  Email: string;
  Extra: string;
  Anexos: AnexosCadastro[];
  Deletado: boolean;
  DeletadoPor: string;
  DeletadoEm: Date | null;
  Alteracoes: any;
  JustificativaAlteracao: string;
}

export class AnexosCadastro {
  Id: any;
  CadastroAgenciaId: number | null;
  SocioAgenciaCadastroId: number | null;
  Nome: string;
  Url: string;
  Extra: string;
  Alteracoes: any;
  JustificativaAlteracao: string;
  Deletado: boolean;
  DeletadoPor: string;
  DeletadoEm: Date | null;
}

export class FiltroCadastroAgencia {
  Id: string;
  RazaoSocial: string;
  CidadeEstado: string;
  DataDe: string | Date;
  DataAte: string | Date;
  Status: number = -2;
  OrderBy: string = "";
}

export interface CadastroAgenciaPortal {
  razaoSocial: string,
  nomeFantasia: string,
  cnpjAgencia: string,
  identificacaoEstadual: string,
  identificacaoMunicipal: string,
  embratur: string,

  telefonePrincipal: string,
  telefoneSecundario: string,
  emailAgencia: string,
  website: string,

  cep: string,
  logradouro: string,
  numero: string,
  bairro: string,
  cidade: string,
  estado: string,
  complemento: string,

  nomeBanco: string,
  numeroBanco: string,
  agenciaBanco: string,
  numeroConta: string,

  idioma: string,
  quantidadeSocios: string,

  contratoSocial: any,
  cartaoCPJ: any,
  comprovanteEndereco: any,
  identificacaoBancaria: any,
  doccadastur: any;
  docembratur: any;
  logoempresa: any;

  nomeSocio: string,
  cpfSocio: string,
  rgSocio: string,
  telefoneSocio: string,
  emailSocio: string,
  enderecoSocio: string,
  rgcnhSocio: any;
  comprovanteResidenciaSocio: any;
}

