import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BuscaAereoRQ } from '../../model';

import { API, TURISMO_AEREO } from '../../app.config';
import { ITENSPORPAGINA } from '../../app.config';
import { CLIENT_ID } from '../../app.config';

@Injectable()
export class AereoService {
  constructor(private http: HttpClient) { }

  buscarAereo(busca: BuscaAereoRQ, pagina: Number): Observable<any> {
    return this.http.post<any>(`${TURISMO_AEREO}/api/Aereo/busca?Pagina=${pagina}&ItensPorPagina=${ITENSPORPAGINA}`, busca, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  removerAereo(aereo: any): Observable<any> {
    return this.http.delete<any>(`${TURISMO_AEREO}/api/Aereo/${aereo.Id}`);
  }

  tarifarAereo(aereo: any): Observable<any> {
    return this.http.post<any>(`${TURISMO_AEREO}/api/Aereo/tarifar/${aereo.Id}`, {}, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  tarifarNaListagem(aereo: any): Observable<any> {
    return this.http.post<any>(`${TURISMO_AEREO}/api/aereo/tarifagemListagem`, aereo, { headers: new HttpHeaders()});
  }

  regrasTarifarias(aereo: any): Observable<any> {
    return this.http.post<any>(`${TURISMO_AEREO}/api/aereo/regras-tarifarias/${aereo.Id}`, {}, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  reservarAereo(aereo: any): Observable<any> {
    return this.http.post<any>(`${TURISMO_AEREO}/api/aereo/reservar/${aereo.Id}`, aereo, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  cancelarAereo(aereo: any): Observable<any> {
    return this.http.post<any>(`${TURISMO_AEREO}/api/aereo/cancelar/${aereo.Id}`, aereo, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  emitirAereo(aereo: any): Observable<any> {
    return this.http.post<any>(`${TURISMO_AEREO}/api/aereo/emitir/${aereo.Id}`, aereo, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  buscarAereoPorId(id): Observable<any> {
    return this.http.get<any>(`${TURISMO_AEREO}/api/aereo/${id}`, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  alterarStatus(alteracao: any): Observable<any> {
    return this.http.put<any>(`${TURISMO_AEREO}/api/aereo/${alteracao.Id}`, alteracao, { headers: new HttpHeaders().set('noloader', 'true') });
  }

  removerVoo(id: Number): Observable<any> {
    return this.http.delete<any>(`${TURISMO_AEREO}/api/voo/${id}`);
  }

  removerSeguimento(id: Number): Observable<any> {
    return this.http.delete<any>(`${TURISMO_AEREO}/api/seguimento/${id}`);
  }
}
