import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../services/login.service';

import { ParametroService } from '../../services/configuracao/parametro.service';
import { URLSITE, API, THEME } from '../../app.config';

import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'login-light',
  styleUrls: ['./login-light.component.scss'],
  templateUrl: './login-light.component.html'
})
export class LoginLightComponent implements OnInit {
  protected options: {};
  redirectDelay: number;
  showMessages: any;
  strategy: string;
  errors: string[];
  messages: string[];
  valorParametro: any;
  carregando: boolean = false;

  public user: any = { email: '', password: '' };
  submitted: boolean = false;
  rememberMe: boolean;

  verRecuperarSenha = false;
  verLogin = true;
  emailSenha = ''
  enviado = false;
  successSenha: any = null;
  errorSenha: any = null;
  Sair = false;
  Cancelar = true;
  urlSite = URLSITE;
  enderecoapi = API;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private parametroService: ParametroService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this.loginService.isLoggedIn();
  }

  getConfigValue(key: string): any {

  }

  login() {
    this.errors = [];
    this.carregando = true;
    this.loginService.logar(this.user.email, this.user.password).subscribe(
      dados => {
        this.modalService.dismissAll();
      },
      erro => {
        if (erro.status == 0) {
          this.errors.push('O servidor não respondeu, tente novamente mais tarde.');
          console.log(erro);
        } else {

          if (erro.error && erro.error.error_description) {
            this.errors.push(erro.error.error_description);
          }
          else if (erro.error) {
            this.errors.push(erro.error);
          }
          else {
            this.errors.push(erro);
          }

        }
        this.user.password = '';
        this.carregando = false;
      },
    );
  }


  clicou = true;

  toggleRecuperarSenha(event) {

    if (event) {
      event.preventDefault();
    }

    this.verRecuperarSenha = !this.verRecuperarSenha;
    this.verLogin = !this.verLogin;
    this.clicou = !this.clicou;
    this.errors = [];

  }

  recuperarSenha(event) {
    if (event) {
      event.preventDefault();
    }
    this.successSenha = null
    this.errorSenha = null;
    this.loginService.recuperarSenha({ Email: this.emailSenha }).subscribe(
      dados => {
        this.enviado = false;
        this.successSenha = 'Senha alterada com sucesso! Verifique seu email';
        this.Sair = true;
        this.Cancelar = false;

      },
      retorno => {
        if (retorno.status == 0) {
          this.errorSenha = ['O servidor não respondeu, tente novamente mais tarde.'];
        } else {
          this.errorSenha = [retorno.error.mensagem];
        }
      },
    );

  }

}
