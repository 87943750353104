import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';
import {
  NbAuthComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';

import { NgxAuthComponent } from './auth/auth.component';

import { LoginComponent } from './auth/login/login.component';
import { LoggedInGuard, UsaTurismo } from './services';
import { ContratoComponent } from './contrato/contrato.component';
import { TwoFactorAuthComponent } from './auth/two-factor-auth/two-factor-auth.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';


const routes: Routes = [
  {
    path: "",
    loadChildren: '../app/pages/pages.module#PagesModule',
    // loadChildren: () => import('../app/pages/pages.module').then(mod => mod.PagesModule),
    //loadChildren: './pages/pages.module#PagesModule'
  },

  {
    path: "voucher",
    loadChildren: './voucher/voucher.module#VoucherModule',
    //loadChildren: './pages/pages.module#PagesModule'
  },
  {
    path: "orcamento",
    loadChildren: './orcamento/orcamento.module#OrcamentoModule',
    //loadChildren: './pages/pages.module#PagesModule'
  },
//   {
//     path: "orcamento-pacote",
//     loadChildren: './orcamento-pacote/orcamento-pacote.module#OrcamentoPacoteModule',
//     //loadChildren: './pages/pages.module#PagesModule'
//   },
  {
    path: "auth",
    component: NgxAuthComponent,
    children: [
      { path: "", component: LoginComponent },
      { path: "login/:lang", component: LoginComponent },      
      { path: "autenticacao", component: TwoFactorAuthComponent },
      {
        path: "register/:lang",
        component: NbRegisterComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: "logout/:lang",
        component: NbLogoutComponent,
        canActivate: [LoggedInGuard],
      },
      // { path: "request-password", component: RecuperarSenhaComponent },
      // { path: "reset-password", component: NbResetPasswordComponent },
    ],
  },

  { path: 'sign-up', component: SignUpComponent },
  // { path: 'sign-up/:lang', component: SignUpComponent },

  {
    path: 'contrato',
    component: ContratoComponent
  },
];

const config: ExtraOptions = {
  useHash: true,
  anchorScrolling: 'enabled'
};


@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  static forRoot(): ModuleWithProviders{
    return {
      ngModule: AppRoutingModule,
      providers: [  LoggedInGuard, UsaTurismo  ],
    };
  }
}
